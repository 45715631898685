import React from 'react'
import "./src/tailwind.css"
import "./src/index.css"
import ContextProvider from './src/components/ContextProvider'
import Amplify from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import awsconfig from './src/aws-exports'

Amplify.configure(awsconfig)
Auth.configure(awsconfig)

const mobileScript = "bbe4a4eaa7"
const desktopScript = "f57e5043c3"

export const wrapRootElement = ({ element }) => (
  <ContextProvider>{element}</ContextProvider>
)

const noScriptLocations = [
  '/7-free-filing-tips',
  '/app/',
  '/admin/',
]

const addScript = () => {

  const windowWitdth = window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;
  const isMobile = true // windowWitdth < 768
  const script = document.createElement("script")
  script.src = `https://waxcarvers.ck.page/${isMobile ? mobileScript : desktopScript}/index.js`
  script.async = true
  script.setAttribute('data-uid', isMobile ? mobileScript : desktopScript)
  const existing = document.CONVERTKIT_SCRIPT_ADDED
  if(!existing){
    document.CONVERTKIT_SCRIPT_ADDED = true;
    document.body.appendChild(script)
  }

}
const removeScript = () => {
  const existingDivs = document.getElementsByClassName('formkit-slide-in')
  const existingMobileDivs = document.getElementsByClassName('formkit-overlay')
  if(document.CONVERTKIT_SCRIPT_ADDED){
    if(existingDivs){
      let counter = 0
      while(existingDivs.length){
        existingDivs[counter].remove()
        counter++
      }
    }
    if(existingMobileDivs){
      let counter = 0
      while(existingMobileDivs.length){
        existingMobileDivs[counter].remove()
        counter++
      }
    }
    document.CONVERTKIT_SCRIPT_ADDED = false;
  }

}

export const onRouteUpdate = ({location}) => {
  if(noScriptLocations.find(it => location.pathname.includes(it))){
    removeScript()
  }else {
    addScript()
  }
}

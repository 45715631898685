/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "comms",
            "endpoint": "https://t3ifc9mpsg.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "getStripeItems",
            "endpoint": "https://pn8ph8iu6e.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "items",
            "endpoint": "https://3ngl7p8nsi.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "questionresponse",
            "endpoint": "https://o0mcvlnj22.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "resources",
            "endpoint": "https://caxgl38ivg.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "stripecheckout",
            "endpoint": "https://2y1lg66ar5.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "webhookstripe",
            "endpoint": "https://s9zba9rcvl.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://vqrn6ty265dvplrgsjhgcjxflu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-1:c7ab66e0-9f30-4b11-9cce-1d3081d91258",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_gCVufulAG",
    "aws_user_pools_web_client_id": "626e0e84gcnv1n55mel5ulpq8i",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "waxcarvingcourse-20201101113241-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d3qn30mesvupkt.cloudfront.net",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "communications-ddb-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "purchaseslink-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "purchases_nl-dev",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "waxcarvingcourse0c687570c00346b48a09b681c00f44295229-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;

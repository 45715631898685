import React, { useEffect, useState } from 'react'
import { AppContext } from '../context'
import { API, Auth } from 'aws-amplify'
import useSWR from 'swr'

const emptyState = { products: [] }
const ContextProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const getItems = async () => {
    const fetchItems = async () => {
      const apiName = 'items'
      const apiEndpoint = '/items'
      return await API.get(apiName, apiEndpoint)
    }
    const items = await fetchItems()
    return { products: items.items }
  }
  const { data } = useSWR('items/items', getItems)
  const getCurrentUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      setUser(user)
    } catch (e) {
      console.debug('Failed to get current user')
    }
  }

  useEffect(() => {
    getCurrentUser()
  }, [])

  const context = {
    state: data || emptyState,
    user, getCurrentUser
  }

  return (
    <AppContext.Provider value={context}>
      {children}
    </AppContext.Provider>
  )
}
export default ContextProvider
